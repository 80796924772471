/**
 * Use within a `class` property to allow multiple css class*es* to be applied (concatenated).
 *
 * Example:
 * ```tsx
 * <div class={es(styles.text, styles.bold)}>Hello</div>
 * ```
 */
export function es(...classes: (string | undefined)[]): string {
  return classes.filter(Boolean).join(" ")
}
