import {
  ActionCodeInfo,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  linkWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signOut as signOutUser,
  updateProfile,
} from "firebase/auth"
import { LocalStorageKey } from "../data/types"
import { auth } from "../firebase"
import { Analytics } from "./Analytics"

async function signUpAnonymously(): Promise<void> {
  try {
    const { user } = await signInAnonymously(auth)

    Analytics.setUserId(user.uid)
    Analytics.event(Analytics.Event.userSignedUp, { method: "anonymous" })
  } catch (error) {
    console.error("Sign-up failed:", error)
    throw error
  }
}

export type SignUpDetails = {
  name: string
  email: string
  password: string
  /** The anonymous user ID */
  anonymousId?: string
}

async function signUpWithEmail(details: SignUpDetails): Promise<void> {
  const name = details.name.trim()
  const email = details.email.trim().toLowerCase()
  const password = details.password
  const userId = details.anonymousId

  try {
    let authUser = auth.currentUser

    if (authUser) {
      // Link to existing anonymous account
      if (!userId) throw new Error("Anonymous user ID is required to link to existing account")
      if (userId !== authUser?.uid) throw new Error("Anonymous user ID does not match current user")
      if (!authUser?.isAnonymous) throw new Error("Current user is not anonymous")

      await linkWithCredential(authUser, EmailAuthProvider.credential(email, password))
    } else {
      // Create new account
      authUser = (await createUserWithEmailAndPassword(auth, email, password)).user
    }

    await updateProfile(authUser, { displayName: name })
    await authUser.reload() // Reload so listeners get updated profile

    Analytics.setUserId(authUser.uid)
    if (userId) Analytics.event(Analytics.Event.userConvertedToKnownAccount)
    else Analytics.event(Analytics.Event.userSignedUp, { method: "email" })
  } catch (error) {
    Analytics.error(error)
    throw error
  }
}

export type SignInDetails = {
  email: string
  password: string
}

async function signInWithEmail(details: SignInDetails): Promise<void> {
  const email = details.email.trim()
  const password = details.password

  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password)

    Analytics.setUserId(user.uid)
    Analytics.event(Analytics.Event.userSignedIn, { method: "email" })
    localStorage.setItem(LocalStorageKey.DefaultAuthScreen, "login")
  } catch (error) {
    console.error("Sign-in failed:", error)
    throw error
  }
}

async function sendResetPasswordEmail(email: string): Promise<void> {
  try {
    await sendPasswordResetEmail(auth, email.trim())
  } catch (error) {
    console.error("Password reset email failed:", error)
    throw error
  }
}

async function checkLinkCode(code: string): Promise<ActionCodeInfo> {
  return checkActionCode(auth, code)
}

async function resetPassword(code: string, newPassword: string): Promise<void> {
  try {
    await confirmPasswordReset(auth, code, newPassword)
  } catch (error) {
    console.error("Password reset failed:", error)
    throw error
  }
}

async function signOut(): Promise<void> {
  try {
    await signOutUser(auth)
    Analytics.event(Analytics.Event.userSignedOut)
    Analytics.unsetUser()
  } catch (error) {
    console.error("Sign-out failed:", error)
    throw error
  }
}

export const Auth = {
  signUpAnonymously,
  signUpWithEmail,
  signInWithEmail,
  sendResetPasswordEmail,
  checkLinkCode,
  resetPassword,
  signOut,
}
