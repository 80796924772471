import { A, Navigate, useNavigate } from "@solidjs/router"
import { AuthError } from "firebase/auth"
import { Component, Show, createSignal } from "solid-js"
import { useAppData } from "../../data/AppData"
import { Analytics } from "../../utils/Analytics"
import { Auth, SignInDetails } from "../../utils/Auth"
import { formDataFrom } from "../../utils/Form"
import { AuthScreen } from "./AuthScreen"
import styles from "./AuthScreen.module.scss"
import { EmailInput, PasswordInput } from "./Inputs"

export const SignInScreen: Component = () => {
  const [validating, setValidating] = createSignal(false)
  const [errorMessage, setErrorMessage] = createSignal<string>()
  const navigate = useNavigate()
  const [data] = useAppData()

  const userAlreadyHasAccount = () => !!data.user

  const [emailSubmitted, setEmailSubmitted] = createSignal<string>()
  const forgotPasswordUrl = () => {
    let url = "/forgot-password"
    const email = emailSubmitted()
    if (email) url += "?" + new URLSearchParams([["email", email]])
    return url
  }

  const validateAndSignIn = async (form: HTMLFormElement) => {
    setValidating(true)
    setErrorMessage()

    if (form.reportValidity()) {
      try {
        const signInDetails = formDataFrom<SignInDetails>(form)
        setEmailSubmitted(signInDetails.email)
        await Auth.signInWithEmail(signInDetails)
        navigate("/")
      } catch (error) {
        console.warn(error)
        if ((error as AuthError)?.code === "auth/user-not-found") {
          setErrorMessage("No account with this email exists. Try creating an account instead.")
        } else if ((error as AuthError)?.code === "auth/wrong-password") {
          setErrorMessage("Either your email or password is incorrect.")
        } else if ((error as AuthError)?.code === "auth/too-many-requests") {
          setErrorMessage(
            "Too many login attempts. Please reset your password, or try again later.",
          )
        } else if ((error as AuthError)?.code === "auth/network-request-failed") {
          setErrorMessage("There was an issue connecting. Please try again later.")
        } else {
          setErrorMessage("Hmmm something went wrong. Try it again.")
          Analytics.error(new Error(`Unknown sign in error`, { cause: error }))
        }
      }
    }
    setValidating(false)
  }

  return (
    <Show when={!userAlreadyHasAccount()} fallback={<Navigate href="/" />}>
      <AuthScreen canSkip>
        <form
          novalidate
          class={styles.form}
          onSubmit={(event) => {
            event.preventDefault()
            if (!validating()) validateAndSignIn(event.currentTarget)
          }}
        >
          <h1>Login</h1>
          <p class={styles.description}>Securely access all your notes.</p>
          <EmailInput validating={validating()} requiredError="You missed your email" />
          <PasswordInput
            validating={validating()}
            requiredError="You missed your password"
            autocomplete="current-password"
          />
          <p class={styles.forgotPassword}>
            <A href={forgotPasswordUrl()}>Forgot password?</A>
          </p>

          {errorMessage() && <p class={styles.error}>{errorMessage()}</p>}

          <button type="submit" disabled={validating()}>
            Login
          </button>

          <p class={styles.altAuth}>
            New to Jotik? <A href="/create-account">Create your account</A>
          </p>
        </form>
      </AuthScreen>
    </Show>
  )
}
