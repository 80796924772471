import { JSX, ParentProps } from "solid-js"
import { es } from "../utils/style"

type ButtonProps = ParentProps<{
  onClick?: JSX.EventHandlerUnion<HTMLButtonElement, MouseEvent>
  disabled?: boolean
  class?: string
  classList?: Record<string, boolean>
  title?: string
}>
export const Button = (props: ButtonProps) => (
  <button
    class={es(props.class, props.class)}
    classList={props.classList}
    onPointerDown={(event) => event.preventDefault()}
    onMouseDown={(event) => event.preventDefault()}
    onClick={(event) => typeof props.onClick === "function" && props.onClick(event)}
    disabled={props.disabled}
    title={props.title}
  >
    {props.children}
  </button>
)
