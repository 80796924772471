import { Component, For, Show, createSelector } from "solid-js"
import { useAppData } from "../data/AppData"
import { Scrollable } from "./Scrollable"
import styles from "./Sidebar.module.scss"

export const Sidebar: Component = () => {
  const [data, { search }] = useAppData()

  const isSelected = createSelector(() => data.filters.searchTerm.split(" ", 1)[0])

  const tags = () =>
    data.tags.byUsage
      .filter((tag) => tag.cumulativeUsageCount > 0)
      .sort((a, b) => a.tag.localeCompare(b.tag))

  const hasTags = () => tags().length > 0

  const setTag = (tag: string) => search(`#${tag}`)

  return (
    <Show when={hasTags()}>
      <Scrollable class={styles.sidebar}>
        <For each={tags()}>
          {(tag) => (
            <button
              onClick={() => setTag(tag.tag)}
              classList={{ [styles.selected]: isSelected(`#${tag.tag}`) }}
            >
              <span>#{tag.tag}</span>
              <span class={styles.pill}>{tag.usageCount}</span>
            </button>
          )}
        </For>
      </Scrollable>
    </Show>
  )
}
