import { unwrap } from "solid-js/store"
import { LocalStorageKey } from "../data/types"
import { jsonDateReviver } from "./jsonReviver"

/** Gets a store data object from `localStorage`. */
export function getLocalData<T>(key: LocalStorageKey): T | undefined {
  const storedStr = localStorage.getItem(key)
  return storedStr ? (JSON.parse(storedStr, jsonDateReviver) as T) : undefined
}

/** Saves a store data object in `localStorage`. */
export function setLocalData<T>(key: LocalStorageKey, data: T) {
  localStorage.setItem(key, JSON.stringify(unwrap(data)))
}

/** Removes the store data object from `localStorage`. */
export function removeLocalData(key: LocalStorageKey) {
  localStorage.removeItem(key)
}
