import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import calendar from "dayjs/plugin/calendar"
dayjs.extend(advancedFormat)
dayjs.extend(calendar)

const RECENT_DATE_FORMAT = {
  sameDay: "[Today at] h:mma", // The same day (Today at 2:32pm)
  lastDay: "[Yesterday at] h:mma", // The day before (Yesterday at 2:32pm)
  lastWeek: "[Last] ddd[,] Do MMM", // Last week (Last Monday, 12th Feb)
  sameElse: "Do MMM", // This year (17th Oct)
}
const PREV_YEARS = "Do MMM YYYY" // Everything else (2nd Mar 2019)

export function formatDate(date: Date | null | undefined): string {
  if (!date) return "now"
  const _date = dayjs(date)

  return _date.isSame(dayjs(), "year")
    ? _date.calendar(null, RECENT_DATE_FORMAT)
    : _date.format(PREV_YEARS)
}

export function tomorrow(date?: Date): Date {
  return dayjs(date).add(1, "day").startOf("day").toDate()
}

export function isInPast(date: Date | null): boolean {
  return dayjs(date).isBefore(dayjs())
}
