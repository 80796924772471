import hotkeys, { HotkeysEvent } from "hotkeys-js"
import { onCleanup, onMount } from "solid-js"

// By default hotkeys are not enabled for INPUT SELECT TEXTAREA elements.
// This removes these filters.
hotkeys.filter = () => true

type KeyHandler = (keyboardEvent: KeyboardEvent, hotkeysEvent: HotkeysEvent) => void

/**
 * Creates a keyboard shortcut. Automatically unbinds it when the component unmounts.
 *
 * @see https://www.npmjs.com/package/hotkeys-js
 */
export function createShortcut(key: string, method: KeyHandler): void {
  onMount(() => {
    hotkeys(key, (event, handler) => {
      method(event, handler)
      return false // Stops propagation and prevents default browser events
    })

    onCleanup(() => hotkeys.unbind(key))
  })
}
