import {
  AutoLinkPlugin as BaseAutoLinkPlugin,
  LinkMatcher,
} from "lexical-solid/LexicalAutoLinkPlugin"

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const MATCHERS: LinkMatcher[] = [
  (text) => {
    const match = URL_MATCHER.exec(text)
    if (match === null) return null

    const fullMatch = match[0]
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
      attributes: { rel: "noopener", target: "_blank" },
    }
  },
]

export const AutoLinkPlugin = () => <BaseAutoLinkPlugin matchers={MATCHERS} />
