import { Route, Router, Routes } from "@solidjs/router"
import { onMount } from "solid-js"
import { AppStateProvider } from "../data/AppData"
import { Analytics } from "../utils/Analytics"
import { onAppInstall } from "../utils/OnPwaInstall"
import { AppContainer } from "./AppContainer"
import { NotesScreen } from "./NotesScreen"
import { ProtectedRoute } from "./ProtectedRoute"
import { AuthActionScreen } from "./auth/AuthActionScreen"
import { ForgotPasswordScreen } from "./auth/ForgotPasswordScreen"
import { SignInScreen } from "./auth/SignInForm"
import { SignUpScreen } from "./auth/SignUpForm"
import { PrivacyPolicyScreen } from "./legal/PrivacyPolicyScreen"
import { TermsAndConditionsScreen } from "./legal/TermsAndConditionsScreen"
import { PreferencesScreen } from "./preferences/PreferencesScreen"
import { ProfileScreen } from "./preferences/ProfileScreen"

const App = () => {
  // Only fire App Launched event in prod as a way of debouncing
  onMount(() => Analytics.event(Analytics.Event.appLaunched))

  onAppInstall(() => Analytics.event(Analytics.Event.appInstalled))

  return (
    <AppContainer>
      <AppStateProvider>
        <Router>
          <Routes>
            <Route path="/login" component={SignInScreen} />
            <Route path="/create-account" component={SignUpScreen} />
            <Route path="/forgot-password" component={ForgotPasswordScreen} />
            <Route path="/auth" component={AuthActionScreen} />
            <Route path="/privacy-policy" component={PrivacyPolicyScreen} />
            <Route path="/terms-and-conditions" component={TermsAndConditionsScreen} />
            {/* Protected app routes */}
            <ProtectedRoute path="/preferences" component={PreferencesScreen} />
            <ProtectedRoute path="/profile" component={ProfileScreen} />
            <ProtectedRoute path="/*all" component={NotesScreen} />
          </Routes>
        </Router>
      </AppStateProvider>
    </AppContainer>
  )
}

export default App
