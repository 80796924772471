import { $isLinkNode } from "@lexical/link"
import {
  $isListNode,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
} from "@lexical/list"
import { $isAtNodeEnd, $isParentElementRTL, $wrapNodes } from "@lexical/selection"
import { $getNearestNodeOfType } from "@lexical/utils"
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  CLEAR_EDITOR_COMMAND,
  EditorState,
  FORMAT_TEXT_COMMAND,
  RangeSelection,
} from "lexical"
import { useLexicalComposerContext } from "lexical-solid/LexicalComposerContext"
import { OnChangePlugin } from "lexical-solid/LexicalOnChangePlugin"
import { IconTypes } from "solid-icons"
import {
  BiRegularBold,
  BiRegularCheckboxChecked,
  BiRegularCodeAlt,
  BiRegularFontColor,
  BiRegularHash,
  BiRegularItalic,
  BiRegularListOl,
  BiRegularListUl,
  BiRegularSend,
  BiRegularStrikethrough,
  BiRegularUnderline,
} from "solid-icons/bi"
import { For, JSX, Match, Switch, batch, createSignal } from "solid-js"
import { createStore } from "solid-js/store"
import { Dynamic } from "solid-js/web"
import { useAppData } from "../../data/AppData"
import { SaveNote } from "../../data/notesData"
import { Analytics } from "../../utils/Analytics"
import { Scrollable } from "../Scrollable"
import { COMPLETE_HASHTAG_COMMAND, INSERT_HASHTAG_COMMAND } from "../rich-text/HashTagPlugin"
import styles from "./NoteEditor.module.scss"

type ClickHandler = () => void

type EditorToolbarProps = {
  onTagClick?: ClickHandler
  onImageClick?: ClickHandler
  onFileClick?: ClickHandler
  onMicClick?: ClickHandler
  onReminderClick?: ClickHandler
  saveDisabled?: boolean
  tagSearch: string | null
  onTagSelect?: (tag: string) => void
}

export const NoteEditorToolbar = (props: EditorToolbarProps) => {
  const [data, { saveNote, saveTagsFromNote, clearDraftNote }] = useAppData()
  const [editor] = useLexicalComposerContext()

  const [format, setFormat] = createStore({
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrikethrough: false,
    isCode: false,
    isLink: false,
    isHashtag: false,
    isBulletList: false,
    isNumberList: false,
    isCheckList: false,
    isRTL: false,
  })

  const [isTextFormatOpen, setIsTextFormatOpen] = createSignal(false)

  const filteredTags = (): string[] => {
    const tagSearch = props.tagSearch
    if (tagSearch === null) return []

    let fTags = [...data.tags.byCumulativeUsage.map(({ tag }) => tag)]
    if (tagSearch !== "") fTags = fTags.filter((tag) => tag.startsWith(tagSearch)).slice(0, 20)

    if (tagSearch.length > 0 && !fTags.includes(tagSearch)) fTags.push(tagSearch)

    return fTags
  }

  const onSave = async () => {
    navigator.vibrate?.(10)
    const editorState = editor.getEditorState()
    let note: SaveNote

    editorState.read(() => {
      const lexicalContent = JSON.stringify(editorState)
      const rootNode = $getRoot()
      const plainContent = rootNode.getTextContent()
      const id = data.draftNote.id
      note = { id, lexicalContent, plainContent }
      batch(() => {
        saveNote(note)
        saveTagsFromNote(note)
        clearDraftNote()
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
      })

      Analytics.event(Analytics.Event.noteSaved, {
        isNew: id === undefined,
        characterLength: plainContent.length,
      })
    })
  }

  const updateToolbar = (editorState: EditorState) => {
    editorState.read(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        batch(() => {
          setIsTextFormatOpen(!selection.isCollapsed())

          const anchorNode = selection.anchor.getNode()
          const elementNode =
            anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow()

          // Text format
          setFormat("isBold", selection.hasFormat("bold"))
          setFormat("isItalic", selection.hasFormat("italic"))
          setFormat("isUnderline", selection.hasFormat("underline"))
          setFormat("isStrikethrough", selection.hasFormat("strikethrough"))
          setFormat("isCode", selection.hasFormat("code"))
          setFormat("isRTL", $isParentElementRTL(selection))
          // List format
          const listType = $isListNode(elementNode)
            ? $getNearestNodeOfType(anchorNode, ListNode)?.getListType()
            : undefined
          setFormat("isBulletList", listType === "bullet")
          setFormat("isNumberList", listType === "number")
          setFormat("isCheckList", listType === "check")
          // Link Format
          const node = getSelectedNode(selection)
          const parent = node.getParent()
          setFormat("isLink", $isLinkNode(parent) || $isLinkNode(node))
          // TODO hashtag
        })
      }
    })
  }

  const listItemToParagraph = () => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) $wrapNodes(selection, () => $createParagraphNode())
    })
  }

  return (
    <div class={styles.toolbar}>
      <OnChangePlugin onChange={updateToolbar} />
      <Switch
        fallback={
          <>
            <div class={styles.controls}>
              <EditorButton
                icon={BiRegularHash}
                title="Enter tag"
                onClick={() => editor.dispatchCommand(INSERT_HASHTAG_COMMAND, undefined)}
                isSelected={format.isHashtag}
              />
              <EditorButton
                icon={BiRegularFontColor}
                onClick={() => setIsTextFormatOpen((prev) => !prev)}
              />
              <EditorButton
                icon={BiRegularCheckboxChecked}
                onClick={() =>
                  !format.isCheckList
                    ? editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isCheckList}
              />
              <EditorButton
                icon={BiRegularListUl}
                onClick={() =>
                  !format.isBulletList
                    ? editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isBulletList}
              />
              <EditorButton
                icon={BiRegularListOl}
                onClick={() =>
                  !format.isNumberList
                    ? editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isNumberList}
              />
              {/* <EditorButton
                icon={BiRegularImage}
                disabled
                onClick={() => alert("Image uploads coming soon")}
              />
              <EditorButton
                icon={BiRegularPaperclip}
                disabled
                onClick={() => alert("File uploads coming soon")}
              />
              <EditorButton
                icon={BiRegularBell}
                disabled
                onClick={() => alert("Reminders coming soon")}
              />
              <EditorButton
                icon={BiRegularMicrophone}
                disabled
                onClick={() => alert("Voice to text coming soon")}
              /> */}
            </div>
          </>
        }
      >
        <Match when={isTextFormatOpen()}>
          <>
            <div class={styles.controls}>
              <EditorButton
                icon={BiRegularBold}
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
                isSelected={format.isBold}
              />
              <EditorButton
                icon={BiRegularItalic}
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
                isSelected={format.isItalic}
              />
              <EditorButton
                icon={BiRegularUnderline}
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
                isSelected={format.isUnderline}
              />
              <EditorButton
                icon={BiRegularStrikethrough}
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
                isSelected={format.isStrikethrough}
              />
              <EditorButton
                icon={BiRegularCheckboxChecked}
                onClick={() =>
                  !format.isCheckList
                    ? editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isCheckList}
              />
              <EditorButton
                icon={BiRegularListUl}
                onClick={() =>
                  !format.isBulletList
                    ? editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isBulletList}
              />
              <EditorButton
                icon={BiRegularListOl}
                onClick={() =>
                  !format.isNumberList
                    ? editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
                    : listItemToParagraph()
                }
                isSelected={format.isNumberList}
              />
              <EditorButton
                icon={BiRegularCodeAlt}
                onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")}
                isSelected={format.isCode}
              />
            </div>
          </>
        </Match>
        <Match when={props.tagSearch !== null}>
          <Scrollable horizontal class={styles.tags}>
            <For each={filteredTags()} fallback={<NoTag />}>
              {(tag) => (
                <Button
                  title={`Insert tag #${tag}`}
                  onClick={() => editor.dispatchCommand(COMPLETE_HASHTAG_COMMAND, tag)}
                >
                  #{tag}
                </Button>
              )}
            </For>
          </Scrollable>
        </Match>
      </Switch>
      <div class={styles.save}>
        <EditorButton
          icon={BiRegularSend}
          onClick={() => onSave()}
          disabled={props.saveDisabled}
          title="Save note"
        />
      </div>
    </div>
  )
}

export const NoTag = () => <span>Start typing to create your first tag</span>

type ButtonProps = Pick<
  JSX.ButtonHTMLAttributes<HTMLButtonElement>,
  "onClick" | "class" | "classList" | "title" | "disabled" | "children"
>
const Button = (props: ButtonProps) => (
  <button
    class={props.class}
    classList={props.classList}
    onPointerDown={(event) => event.preventDefault()}
    onMouseDown={(event) => event.preventDefault()}
    onClick={(event) => typeof props.onClick === "function" && props.onClick(event)}
    disabled={props.disabled}
    title={props.title}
  >
    {props.children}
  </button>
)

type EditorButtonProps = {
  icon: IconTypes
  onClick?: () => void
  isSelected?: boolean
  disabled?: boolean
  title?: string
}
const EditorButton = (props: EditorButtonProps) => (
  <Button
    class={styles.icon}
    classList={{ [styles.selected]: props.isSelected }}
    onClick={props.onClick}
    disabled={props.disabled}
    title={props.title}
  >
    <Dynamic component={props.icon} />
  </Button>
)

function getSelectedNode(selection: RangeSelection) {
  const anchor = selection.anchor
  const focus = selection.focus
  const anchorNode = selection.anchor.getNode()
  const focusNode = selection.focus.getNode()
  if (anchorNode === focusNode) return anchorNode

  const isBackward = selection.isBackward()
  if (isBackward) return $isAtNodeEnd(focus) ? anchorNode : focusNode
  else return $isAtNodeEnd(anchor) ? focusNode : anchorNode
}
