import { collection, doc, writeBatch } from "firebase/firestore"
import { db } from "../../firebase"
import { notesConverter } from "../notesData"
import { tagsConverter } from "../tagsUsageData"
import { Note } from "../types"
import { lexicalNotesMigration } from "./lexicalNotesMigration"
import { localStorageNotesMigration } from "./localStorageNotesMigration"
import { MigrateNote, Migration } from "./types"

const MIGRATIONS: Migration[] = [localStorageNotesMigration, lexicalNotesMigration]

export async function migrateData(notes: Note[], userId: string) {
  const updatedNotes = await MIGRATIONS.reduce<Promise<MigrateNote[]>>(
    (prevNotes, migration) => migration(prevNotes, userId),
    Promise.resolve(notes),
  )
  const hasUpdates = updatedNotes.some((note) => note.dirty)
  if (hasUpdates) await saveNotes(updatedNotes, userId)
}

async function saveNotes(notes: MigrateNote[], userId: string) {
  try {
    const batch = writeBatch(db)

    notes.forEach((note) => {
      if (note.dirty) {
        if (note.id)
          batch.set(doc(db, "notes", note.id).withConverter(notesConverter), note as Note)
        else batch.set(doc(collection(db, "notes")).withConverter(notesConverter), note as Note)
      }
    })

    const tagCounts = notes
      .flatMap((note) => note.tags)
      .reduce<Record<string, number>>(
        (counts, tag) => ({
          ...counts,
          [tag]: (counts[tag] ?? 0) + 1,
        }),
        {},
      )

    const now = new Date()
    Object.entries(tagCounts).forEach(([tag, usageCount]) => {
      batch.set(doc(db, "users", userId, "tags", tag).withConverter(tagsConverter), {
        tag,
        usageCount,
        cumulativeUsageCount: usageCount,
        createdAt: now,
        updatedAt: now,
      })
    })

    await batch.commit()
    console.log("Migration succeeded")
  } catch (error) {
    console.error("Migration failed", error)
  }
}
