import { createContext, createEffect, createMemo, ParentProps, useContext } from "solid-js"
import { createStore, unwrap } from "solid-js/store"
import { initialDraftNote, setupDraftNote } from "./draftNoteData"
import { setupFilters } from "./filterData"
import { migrateData } from "./migrations/migrate"
import { filterNotes, initialNotes, setupNotes } from "./notesData"
import { setupRecentActions } from "./recentActionsData"
import { initialTags, setupTags, sortTagsByUsageCount } from "./tagsUsageData"
import { AppData, Note, Tag } from "./types"
import { initialUserData, setupUser } from "./userData"

type AppActions = ReturnType<typeof setupFilters> &
  ReturnType<typeof setupNotes> &
  ReturnType<typeof setupDraftNote> &
  ReturnType<typeof setupTags> &
  ReturnType<typeof setupUser> &
  ReturnType<typeof setupRecentActions>

type AppContext = [AppData, AppActions]

const AppDataContext = createContext<AppContext>()

export function AppStateProvider(props: ParentProps) {
  const [data, setData] = createStore<AppData>({
    user: initialUserData(),
    filters: {
      searchTerm: "",
      tasksOnly: false,
      archiveOnly: false,
    },
    notes: {
      all: initialNotes(),
      get filtered(): Note[] {
        return filteredNotes()
      },
    },
    draftNote: initialDraftNote(),
    tags: {
      byCumulativeUsage: initialTags(),
      get byUsage(): Tag[] {
        return tagsByUsage()
      },
    },
    recentActions: [],
  })

  // Have to handle filtered notes differently as it uses a getter
  const filteredNotes = createMemo(() => filterNotes(data))
  const tagsByUsage = createMemo(() => sortTagsByUsageCount(data.tags.byCumulativeUsage))

  const actions: AppActions = {
    ...setupFilters(data, setData),
    ...setupNotes(data, setData),
    ...setupDraftNote(data, setData),
    ...setupTags(data, setData),
    ...setupUser(data, setData),
    ...setupRecentActions(data, setData),
  }

  createEffect(function migrate() {
    data.notes.all.length // needed otherwise notes is empty ??
    if (data.user?.id) migrateData(unwrap(data.notes.all), data.user?.id)
  })

  // onMount(function clearPersistedDataOnLogout() {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     // TODO terminate db
  //     if (!user) clearIndexedDbPersistence(db)
  //   })
  //   onCleanup(unsubscribe)
  // })

  return <AppDataContext.Provider value={[data, actions]}>{props.children}</AppDataContext.Provider>
}

/** Use the app data and/or actions to mutate the app data. */
export function useAppData(): AppContext {
  const appContext = useContext(AppDataContext)
  if (!appContext) throw Error("useAppState used outside the scope of the AppDataContext Provider.")

  return appContext
}
