export function debounce<Args extends any[]>(
  callback: (...args: Args) => void,
  waitMs: number,
): (...args: Args) => void {
  let timeoutId: number

  return (...args) => {
    window.clearTimeout(timeoutId)
    timeoutId = window.setTimeout(callback, waitMs, ...args)
  }
}
