import { Navigate, useSearchParams } from "@solidjs/router"
import { Component, ErrorBoundary, Match, Switch } from "solid-js"
import styles from "./AuthScreen.module.scss"
import { ResetPasswordScreen } from "./ResetPasswordScreen"

/** See https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page */
type ActionParams = {
  mode: "resetPassword" | "recoverEmail" | "verifyEmail"
  oobCode: string
  continueUrl?: string
}

export const AuthActionScreen = () => {
  const [searchParams] = useSearchParams<ActionParams>()

  return (
    <ErrorBoundary fallback={(error) => <AuthActionErrorScreen error={error} />}>
      <Switch fallback={<Navigate href="/login" />}>
        <Match when={searchParams.mode === "resetPassword"}>
          <ResetPasswordScreen code={searchParams.oobCode} continueUrl={searchParams.continueUrl} />
        </Match>
      </Switch>
    </ErrorBoundary>
  )
}

/** See https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_1 */
const AuthActionErrorScreen: Component<{ error: any }> = (props) => {
  const errorMessage = () => {
    switch (props.error?.code) {
      case "auth/expired-action-code":
        return "The link used has expired."
      case "auth/invalid-action-code":
      case "auth/user-disabled":
      case "auth/user-not-found":
        return "The link used is no longer valid."
      default:
        return "Hmm something unknown went wrong. Please try again."
    }
  }

  return <div class={styles.actionError}>{errorMessage()}</div>
}
