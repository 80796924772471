import { createHeadlessEditor } from "@lexical/headless"
import { $convertFromMarkdownString } from "@lexical/markdown"
import { $getRoot } from "lexical"
import { markdownTransformers, NODES } from "../../components/rich-text/RichText"
import { Note, NotePoint } from "../types"
import { MigrateNote } from "./types"

export async function lexicalNotesMigration(
  notesPromise: Promise<MigrateNote[]>,
): Promise<MigrateNote[]> {
  try {
    const notes = await notesPromise

    return await Promise.all(
      notes.map(async (note) => {
        if (note.points && !note.lexicalContent?.length) {
          const content = await pointsToLexicalState(note.points)
          return { ...note, ...content, dirty: true }
        }
        return note
      }),
    )
  } catch (error) {
    console.error("Migration to lexical notes failed", error)
    return notesPromise
  }
}

async function pointsToLexicalState(
  points: NotePoint[],
): Promise<Pick<Note, "lexicalContent" | "plainContent">> {
  const lexicalEditor = createHeadlessEditor({
    namespace: "Migration",
    nodes: NODES,
  })

  return new Promise((resolve) => {
    const unsubscribe = lexicalEditor.registerUpdateListener(({ editorState }) => {
      unsubscribe()
      editorState.read(() => {
        const rootNode = $getRoot()
        const lexicalContent = JSON.stringify(editorState)
        const plainContent = rootNode.getTextContent()
        resolve({ lexicalContent, plainContent })
      })
    })

    lexicalEditor.update(() => {
      const markdown = points.reduce<string>(
        (md, point) => md + `\n[${point.completed ? "x" : ""}] ${point.text}`,
        "",
      )

      const root = $getRoot().clear()
      $convertFromMarkdownString(markdown, markdownTransformers, root)
    })
  })
}
