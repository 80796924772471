import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import {
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  CHECK_LIST,
  INLINE_CODE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  STRIKETHROUGH,
  Transformer,
  UNORDERED_LIST,
} from "@lexical/markdown"
import { $getNodeByKey, EditorState, LexicalEditor } from "lexical"
import { ClearEditorPlugin } from "lexical-solid/LexicalClearEditorPlugin"
import {
  InitialConfigType,
  InitialEditorStateType,
  LexicalComposer,
} from "lexical-solid/LexicalComposer"
import { ContentEditable } from "lexical-solid/LexicalContentEditable"
import { LexicalErrorBoundary } from "lexical-solid/LexicalErrorBoundary"
import { HistoryPlugin } from "lexical-solid/LexicalHistoryPlugin"
import { LinkPlugin } from "lexical-solid/LexicalLinkPlugin"
import { ListPlugin } from "lexical-solid/LexicalListPlugin"
import { NodeEventPlugin } from "lexical-solid/LexicalNodeEventPlugin"
import { OnChangePlugin } from "lexical-solid/LexicalOnChangePlugin"
import { RichTextPlugin } from "lexical-solid/LexicalRichTextPlugin"
import { Component, ParentComponent, Show } from "solid-js"
import { es } from "../../utils/style"
import { AutoLinkPlugin } from "./AutoLinkPlugin"
import { CheckListPlugin } from "./CheckListPlugin"
import { HashtagPlugin } from "./HashTagPlugin"
import { LexicalMarkdownShortcutPlugin } from "./LexicalMarkdownShortcutPlugin"
import styles from "./RichText.module.scss"
import { EDITOR_THEME } from "./theme"

export const markdownTransformers: Transformer[] = [
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  BOLD_STAR,
  BOLD_UNDERSCORE,
  CHECK_LIST,
  INLINE_CODE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  LINK,
  ORDERED_LIST,
  STRIKETHROUGH,
  UNORDERED_LIST,
]

type RichTextConfigProps = {
  namespace: string
  editorState?: InitialEditorStateType
  editable?: boolean
}

export const NODES = [HashtagNode, ListNode, ListItemNode, LinkNode, AutoLinkNode]

export const RichTextConfig: ParentComponent<RichTextConfigProps> = (props) => {
  const initialConfig: () => InitialConfigType = () => ({
    namespace: props.namespace,
    theme: EDITOR_THEME,
    onError: (error) => console.error("lexical error", error),
    editorState: props.editorState,
    editable: props.editable ?? false,
    nodes: NODES,
  })

  return <LexicalComposer initialConfig={initialConfig()}>{props.children}</LexicalComposer>
}

type RichTextProps = {
  placeholder?: string
  onChange?: (editorState: EditorState) => void
  onTagClick?: (tag: string) => void
  class?: string
}

export const RichText: Component<RichTextProps> = (props) => {
  const onTagClick = (_event: Event, editor: LexicalEditor, key: string) => {
    editor.getEditorState().read(() => {
      const node = $getNodeByKey(key)
      const tag = node?.getTextContent()
      if (tag) props.onTagClick?.(tag)
    })
  }

  return (
    <div class={es(styles.richInputContainer, props.class)}>
      <RichTextPlugin
        contentEditable={<ContentEditable class={styles.richInput} />}
        placeholder={<div class={styles.placeholder}>{props.placeholder}</div>}
        errorBoundary={LexicalErrorBoundary}
      />
      <HashtagPlugin />
      <LinkPlugin />
      <AutoLinkPlugin />
      {/* <CodeHighlightPlugin /> */}
      <ListPlugin />
      <CheckListPlugin />
      <LexicalMarkdownShortcutPlugin transformers={markdownTransformers} />
      <HistoryPlugin />
      <OnChangePlugin onChange={props.onChange} ignoreSelectionChange />
      <ClearEditorPlugin />
      <Show when={props.onTagClick}>
        <NodeEventPlugin nodeType={HashtagNode} eventType={"click"} eventListener={onTagClick} />
      </Show>
    </div>
  )
}
