import { useNavigate } from "@solidjs/router"
import { IconTypes } from "solid-icons"
import {
  BiRegularBomb,
  BiRegularCheckShield,
  BiRegularChevronRight,
  BiRegularHeartSquare,
  BiRegularLogOut,
  BiRegularMailSend,
  BiRegularToggleLeft,
  BiRegularToggleRight,
  BiRegularUser,
} from "solid-icons/bi"
import { Component, Match, ParentProps, Show, Switch } from "solid-js"
import { Dynamic } from "solid-js/web"
import { useAppData } from "../../data/AppData"
import { Auth } from "../../utils/Auth"
import { BackButton, Header } from "../header/Header"
import { Scrollable } from "../Scrollable"
import styles from "./PreferencesScreen.module.scss"

export const PreferencesScreen = () => {
  const nav = useNavigate()
  const [data] = useAppData()

  function emailUs() {
    window.location.href = encodeURI(
      `mailto:jotik.contact@gmail.com?subject=Feedback&body=Hi Sarah and Scott,\n\n\nMy Jotik user ID is: ${data.user?.id}.\n\nFrom,\n${data.user?.name}`,
    )
  }

  function signOutAnonymousAccount() {
    if (
      confirm(
        "Are you sure you want to clear your data?\n\n" +
          "All your notes will be deleted from your device and cannot be recovered.",
      )
    )
      Auth.signOut()
  }

  return (
    <div class={styles.preferencesScreen}>
      <Header>
        <section>
          <BackButton />
        </section>
      </Header>

      <Scrollable scrollbar class={styles.contentArea}>
        <Show when={!data.user?.isAnonymous}>
          <Profile />
        </Show>

        <div class={styles.menu}>
          <Show when={data.user?.isAnonymous}>
            <MenuButton icon={BiRegularUser} onClick={() => nav("/create-account")} type="nav">
              Create an account to sync your notes across devices
            </MenuButton>
            <hr />
          </Show>

          <MenuButton icon={BiRegularMailSend} onClick={emailUs} type="nav">
            Feedback
          </MenuButton>
          <MenuButton
            icon={BiRegularHeartSquare}
            onClick={() => nav("/terms-and-conditions")}
            type="nav"
          >
            Terms and Conditions
          </MenuButton>
          <MenuButton icon={BiRegularCheckShield} onClick={() => nav("/privacy-policy")} type="nav">
            Privacy policy
          </MenuButton>
          <hr />

          <Show
            when={!data.user?.isAnonymous}
            fallback={
              <MenuButton icon={BiRegularBomb} onClick={() => signOutAnonymousAccount()}>
                Clear your data
              </MenuButton>
            }
          >
            <MenuButton icon={BiRegularLogOut} onClick={() => Auth.signOut()}>
              Logout
            </MenuButton>
          </Show>
        </div>
      </Scrollable>
    </div>
  )
}

const Profile = () => {
  const [data] = useAppData()

  return (
    <div class={styles.profile}>
      <div>{data.user?.name}</div>
      <div>{data.user?.email}</div>
    </div>
  )
}

type MenuButtonProps = ParentProps<{
  icon: IconTypes
  type?: "toggle" | "nav" | "default"
  toggleValue?: boolean
  disabled?: boolean
  onClick: () => void
}>

const MenuButton: Component<MenuButtonProps> = (props) => {
  return (
    <button type="button" disabled={props.disabled} onClick={() => props.onClick()}>
      <div class={styles.icon}>
        <Dynamic component={props.icon} />
      </div>
      <div class={styles.content}>{props.children}</div>
      <div class={styles.action}>
        <Switch>
          <Match when={props.type === "nav"}>
            <BiRegularChevronRight />
          </Match>
          <Match when={props.type === "toggle"}>
            <Show when={props.toggleValue} fallback={<BiRegularToggleLeft />}>
              <BiRegularToggleRight />
            </Show>
          </Match>
        </Switch>
      </div>
    </button>
  )
}
