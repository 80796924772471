import { SetStoreFunction } from "solid-js/store"

export type User = {
  id: string
  name: string | null
  image: string | null
  email: string | null
  authProvider: string
  emailVerified: boolean
  isAnonymous: boolean
  createdAt: Date
}

export type UserData = User | null

export type FiltersData = {
  searchTerm: string
  archiveOnly: boolean
  tasksOnly: boolean
}

export type NotePoint = {
  text: string
  completed: boolean
}

/** @deprecated Use `Note` */
export type LegacyNote = {
  id: string
  owner: string
  editors: string[]
  points: NotePoint[]
  tags: string[]
  createdAt: Date
  updatedAt: Date
  allCompletedAt: Date | null
  archivedAt: Date | null
  serverSyncedAt: Date | null
}

export type Note = {
  id: string
  owner: string
  editors: string[]
  /** @deprecated */
  points?: NotePoint[]
  lexicalContent?: string
  plainContent?: string
  tags: string[]
  createdAt: Date
  updatedAt: Date
  allCompletedAt: Date | null
  archivedAt: Date | null
  serverSyncedAt: Date | null
}

export type NotesData = {
  all: Note[]
  filtered: Note[]
}

export type DraftNoteData = {
  id?: string
  lexicalContent?: string
}

export type Tag = {
  tag: string
  usageCount: number
  cumulativeUsageCount: number
  createdAt: Date
  updatedAt: Date
}

export type TagsData = {
  byUsage: Tag[]
  byCumulativeUsage: Tag[]
}

export enum ActionType {
  noteDeletion = "Note Deletion",
  pointDeletion = "Point Deletion",
}

export type RecentAction =
  | { type: ActionType.noteDeletion; data: { note: Note } }
  | { type: ActionType.pointDeletion; data: { note: Note } }

export type RecentActionsData = RecentAction[]

export type AppData = {
  user: UserData | null
  filters: FiltersData
  notes: NotesData
  draftNote: DraftNoteData
  tags: TagsData
  recentActions: RecentActionsData
}

export type SetAppData = SetStoreFunction<AppData>

export enum LocalStorageKey {
  /** @deprecated */
  LegacyNotes = "notes",
  /** @deprecated */
  LegacyDraftNote = "draft",
  /** @deprecated */
  LegacyTags = "tags",
  /** @deprecated */
  LegacyFilters = "filters",
  User = "user",
  DraftNote = "draft-note",
  NotesMigration = "notes-migration",
  DefaultAuthScreen = "default-auth-screen",
  LatestNotes = "latest-notes",
  latestTags = "latest-tags",
}
