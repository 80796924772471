import { useNavigate } from "@solidjs/router"
import { Component, createSignal, ParentProps, Show } from "solid-js"
import { useAppData } from "../../data/AppData"
import { addOnboardingNote } from "../../data/onboardingNotes"
import { Auth } from "../../utils/Auth"
import { BackButton, Header, HeaderButton } from "../header/Header"
import { Scrollable } from "../Scrollable"
import styles from "./AuthScreen.module.scss"

type AuthScreenProps = ParentProps<{
  canGoBack?: boolean
  canSkip?: boolean
}>

export const AuthScreen: Component<AuthScreenProps> = (props) => {
  const navigate = useNavigate()
  const [, { saveNote }] = useAppData()

  const [loading, setLoading] = createSignal(false)

  async function signUpAnonymously() {
    if (!loading()) {
      setLoading(true)
      try {
        await Auth.signUpAnonymously()
        addOnboardingNote(saveNote)
        navigate("/")
      } catch (error) {
        setLoading(false)
        alert("Hmm something went wrong. Please try again.")
      }
    }
  }

  return (
    <Scrollable class={styles.authScreen} scrollbar>
      <Header noShadow>
        <section>
          <Show when={props.canGoBack}>
            <BackButton />
          </Show>
        </section>
        <section />
        <section>
          <Show when={props.canSkip}>
            <HeaderButton
              title="Sign up anonymously"
              onClick={signUpAnonymously}
              disabled={loading()}
            >
              Skip
            </HeaderButton>
          </Show>
        </section>
      </Header>
      <div class={styles.body}>{props.children}</div>
    </Scrollable>
  )
}
