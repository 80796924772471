import { BackButton, Header } from "../header/Header"
import styles from "./Policy.module.scss"
const TERMS_AND_CONDITIONS_PATH = "/termsAndConditions.html"

export const TermsAndConditionsScreen = () => {
  return (
    <div class={styles.policyPage}>
      <Header>
        <section>
          <BackButton />
        </section>
      </Header>

      <iframe src={TERMS_AND_CONDITIONS_PATH} />
    </div>
  )
}
