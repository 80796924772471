import {
  collection,
  doc,
  FirestoreDataConverter,
  onSnapshot,
  query,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore"
import { createEffect, onCleanup } from "solid-js"
import { reconcile } from "solid-js/store"
import { dateFrom, db, timestampFrom } from "../firebase"
import { debounce } from "../utils/debounce"
import { getLocalData, setLocalData } from "../utils/LocalDataStorage"
import { SaveNote } from "./notesData"
import { AppData, LocalStorageKey, Note, SetAppData, Tag } from "./types"

export function setupTags(data: AppData, setData: SetAppData) {
  createEffect(function syncTagsFromFirestore() {
    const userId = data.user?.id
    if (userId) {
      const tagsQuery = query(collection(db, "users", userId, "tags")).withConverter(tagsConverter)

      const unsubscribe = onSnapshot(tagsQuery, (snapshot) => {
        const tags = sortTagsByCumulativeUsageCount(snapshot.docs.map((doc) => doc.data()))

        setData("tags", "byCumulativeUsage", reconcile(tags, { key: "tag" }))
        setLatestTags(tags)
      })
      onCleanup(unsubscribe)
    } else {
      setData("notes", "all", [])
    }
  })

  function incrementTagUsage(...tags: Tag[]) {
    const userId = data.user?.id
    if (!userId) throw Error("incrementTagUsage was called before a user was authenticated")
    const now = new Date()
    const batch = writeBatch(db)

    tags.forEach((tag) => {
      batch.set(doc(db, "users", userId, "tags", tag.tag).withConverter(tagsConverter), {
        tag: tag.tag,
        usageCount: (tag.usageCount ?? 0) + 1,
        cumulativeUsageCount: (tag.cumulativeUsageCount ?? 0) + 1,
        createdAt: tag.createdAt ?? now,
        updatedAt: now,
      })
    })
    batch.commit() // TODO handle errors
  }

  function decrementTagUsage(...tags: Tag[]) {
    const userId = data.user?.id
    if (!userId) throw Error("incrementTagUsage was called before a user was authenticated")
    const now = new Date()
    const batch = writeBatch(db)

    tags.forEach((tag) => {
      batch.set(doc(db, "users", userId, "tags", tag.tag).withConverter(tagsConverter), {
        tag: tag.tag,
        usageCount: Math.min((tag.usageCount ?? 0) - 1, 0),
        cumulativeUsageCount: tag.cumulativeUsageCount ?? 0,
        createdAt: tag.createdAt ?? now,
        updatedAt: now,
      })
    })
    batch.commit() // TODO handle errors
  }

  function saveTagsFromNote(note: SaveNote) {
    const userId = data.user?.id
    if (!userId) throw Error("incrementTagUsage was called before a user was authenticated")

    const prevTags = (note.id ? data.notes.all.find(({ id }) => id === note.id)?.tags : []) ?? []
    const newTags = tagsIn(note.plainContent!)

    const tagsDiff = new Map<string, number>()
    prevTags.forEach((tag) => tagsDiff.set(tag, -1))

    newTags.forEach((tag) => {
      const prevUsage = tagsDiff.get(tag) ?? 0
      tagsDiff.set(tag, prevUsage + 1)
    })

    const now = new Date()
    const batch = writeBatch(db)

    tagsDiff.forEach((diff, tag) => {
      const tagData = data.tags.byCumulativeUsage.find((tagData) => tagData.tag === tag)
      batch.set(doc(db, "users", userId, "tags", tag).withConverter(tagsConverter), {
        tag,
        usageCount: Math.max((tagData?.usageCount ?? 0) + diff, 0),
        cumulativeUsageCount: Math.max((tagData?.cumulativeUsageCount ?? 0) + Math.max(diff, 0), 0),
        createdAt: tagData?.createdAt ?? now,
        updatedAt: now,
      })
    })
    batch.commit() // TODO handle errors
  }

  return { incrementTagUsage, decrementTagUsage, saveTagsFromNote }
}

export function tagsFromPoints(points: Note["points"]): Note["tags"] {
  const tags = points?.reduce<Note["tags"]>((prev, { text }) => [...prev, ...tagsIn(text)], [])

  return Array.from(new Set(tags))
}

export const tagsIn = (text: string): string[] =>
  Array.from(new Set(text.match(/#[a-z0-9_-]+/gi)?.map((tag) => tag.slice(1).toLowerCase()) ?? []))

export const removeTags = (text: string): string =>
  text.replace(/#[a-z0-9_-]*/gi, "").replace(/\s+/g, " ")

export const dedupe = (arr: string[]): string[] => Array.from(new Set(arr))

export const difference = (arr1: string[], arr2: string[]): string[] =>
  arr1.filter((val) => !arr2.includes(val))

export function sortTagsByUsageCount(tags: Tag[]): Tag[] {
  return [...tags].sort((tag1, tag2) => tag2.usageCount - tag1.usageCount)
}

export function sortTagsByCumulativeUsageCount(tags: Tag[]): Tag[] {
  return [...tags].sort((tag1, tag2) => tag2.cumulativeUsageCount - tag1.cumulativeUsageCount)
}

/** Converts a `Tag` to a Firestore tag doc, and vice-versa. */
export const tagsConverter: FirestoreDataConverter<Tag> = {
  toFirestore: (tag) => ({
    usageCount: tag.usageCount,
    cumulativeUsageCount: tag.cumulativeUsageCount,
    createdAt: timestampFrom(tag.createdAt),
    updatedAt: timestampFrom(tag.updatedAt),
    serverSyncedAt: serverTimestamp(),
  }),
  fromFirestore: (snapshot, options) => {
    const dbNote = snapshot.data(options)
    return {
      tag: snapshot.id,
      usageCount: dbNote.usageCount,
      cumulativeUsageCount: dbNote.cumulativeUsageCount,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      createdAt: dateFrom(dbNote.createdAt)!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      updatedAt: dateFrom(dbNote.updatedAt)!,
      serverSyncedAt: dateFrom(dbNote.serverSyncedAt),
    }
  },
}

export function initialTags(): Tag[] {
  return getLocalData<Tag[]>(LocalStorageKey.latestTags) ?? []
}

const setLatestTags = debounce((tags: Tag[]) => {
  const latestTags = sortTagsByCumulativeUsageCount(tags).slice(0, 10)
  setLocalData(LocalStorageKey.latestTags, latestTags)
}, 1200)
