import { For } from "solid-js"
import { es } from "../utils/style"
import { Button } from "./Button"
import styles from "./HorizontalSelector.module.scss"
import { Scrollable } from "./Scrollable"

export type HorizontalSelectorOption = {
  value: string
  title?: string
  isSelected?: boolean
}

type HorizontalSelectorProps = {
  options: HorizontalSelectorOption[]
  class?: string
  optionClass?: string
  selectedOptionsClass?: string
  placeholder?: string
  onSelect: (option: string) => void
}

export const HorizontalSelector = (props: HorizontalSelectorProps) => (
  <Scrollable horizontal class={es(styles.selector, props.class)}>
    <For each={props.options} fallback={<span>{props.placeholder}</span>}>
      {(option) => (
        <Button
          title={`Select ${option.title ?? option.value}`}
          onClick={() => props.onSelect(option.value)}
          class={props.optionClass}
          classList={{ [styles.selectedOptionsClass]: !!option.isSelected }}
        >
          {option.title}
        </Button>
      )}
    </For>
  </Scrollable>
)

export const NoOption = (props: { children: string }) => (
  <span>
    No <strong>#{props.children.trim()}</strong> tag exists
  </span>
)
