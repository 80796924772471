import { InitialConfigType } from "lexical-solid/LexicalComposer"
import styles from "./RichText.module.scss"

export const EDITOR_THEME: InitialConfigType["theme"] = {
  paragraph: styles.paragraph,
  completedParagraph: styles.completed,
  hashtag: styles.hashtag,
  text: {
    bold: styles.bold,
    code: styles.code,
    italic: styles.italic,
    underline: styles.underline,
    strikethrough: styles.strikethrough,
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: styles.list,
    ul: styles.list,
    listitem: styles.listItem,
    listitemChecked: styles.listItemChecked,
    listitemUnchecked: styles.listItemUnchecked,
  },
}
