/* @refresh reload */
import { render } from "solid-js/web"
import { registerSW } from "virtual:pwa-register"
import App from "./components/App"
import { initializeFirebase } from "./firebase"
import "./index.scss"
import { Analytics } from "./utils/Analytics"

registerSW({ immediate: true })
Analytics.initialize()
initializeFirebase()

render(() => <App />, document.getElementById("root") as HTMLElement)
