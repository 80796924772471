import { IconTypes } from "solid-icons"
import { BiRegularAt, BiRegularFace, BiRegularLock } from "solid-icons/bi"
import { Component, createSignal, JSX, splitProps } from "solid-js"
import { Dynamic } from "solid-js/web"
import styles from "./Inputs.module.scss"

type NameInputProps = Omit<TextInputProps, "type" | "icon">

export const NameInput: Component<NameInputProps> = (props) => (
  <TextInput
    type="text"
    name="name"
    autocomplete="name"
    placeholder="Name"
    icon={BiRegularFace}
    required={!!props.requiredError}
    maxLength={props.maxLengthError ? 60 : undefined}
    {...props}
  />
)

type EmailInputProps = Omit<TextInputProps, "type" | "icon" | "required">

export const EmailInput: Component<EmailInputProps> = (props) => (
  <TextInput
    type="email"
    name="email"
    autocomplete="email"
    placeholder="Email"
    icon={BiRegularAt}
    required={!!props.requiredError}
    {...props}
  />
)

type PasswordInputProps = Omit<TextInputProps, "type" | "icon">

export const PasswordInput: Component<PasswordInputProps> = (props) => (
  <TextInput
    type="password"
    name="password"
    placeholder="Password"
    autocomplete="new-password"
    icon={BiRegularLock}
    required={!!props.requiredError}
    minLength={props.minLengthError ? 6 : undefined}
    maxlength={props.maxLengthError ? 256 : undefined}
    {...props}
  />
)

type TextInputProps = JSX.InputHTMLAttributes<HTMLInputElement> & {
  icon: IconTypes
  validating: boolean
  requiredError?: string
  minLengthError?: string
  maxLengthError?: string
  typeError?: string
  patternError?: string
  defaultError?: string
  errorOverride?: string
}
const CUSTOM_PROPS = [
  "icon",
  "validating",
  "requiredError",
  "minLengthError",
  "maxLengthError",
  "typeError",
  "patternError",
  "defaultError",
  "errorOverride",
] as const

const TextInput: Component<TextInputProps> = (_props) => {
  const [props, inputProps] = splitProps(_props, CUSTOM_PROPS)

  let inputRef: HTMLInputElement
  const [touched, setTouched] = createSignal(false)
  const [errorMessage, setErrorMessage] = createSignal<string>()
  const hasError = () => !!props.errorOverride ?? !!errorMessage()

  const validate = () => {
    if ((props.validating || touched()) && !inputRef.validity.valid)
      setErrorMessage(getError(inputRef.validity))
    else setErrorMessage()
  }

  const getError = (validity: ValidityState) => {
    if (validity.valueMissing) return props.requiredError ?? props.defaultError
    if (validity.tooShort) return props.minLengthError ?? props.defaultError
    if (validity.tooLong) return props.maxLengthError ?? props.defaultError
    if (validity.typeMismatch) return props.typeError ?? props.defaultError
    if (validity.patternMismatch) return props.patternError ?? props.defaultError
  }

  return (
    <label class={styles.input} classList={{ [styles.invalid]: !!hasError() }}>
      <div class={styles.inputBox}>
        <div class={styles.icon}>
          <Dynamic component={props.icon} />
        </div>
        <input
          ref={(ref) => (inputRef = ref)}
          onBlur={() => {
            setTouched(true)
            validate()
          }}
          onInput={() => validate()}
          {...inputProps}
        />
      </div>
      {hasError() && (
        <p class={styles.error} aria-live="polite">
          {props.errorOverride ?? errorMessage()}
        </p>
      )}
    </label>
  )
}
