import { Navigate, Route, RouteProps } from "@solidjs/router"
import { Component, JSX, Show, splitProps } from "solid-js"
import { Dynamic } from "solid-js/web"
import { useAppData } from "../data/AppData"
import { LocalStorageKey } from "../data/types"

/** A `Route` component that will redirect to `/create-account` if the user is unauthorized. */
export const ProtectedRoute = <S extends string>(props: RouteProps<S>): JSX.Element => {
  const [renderProps, routeProps] = splitProps(props, ["component", "element"])

  const [data] = useAppData()

  const defaultAuthScreen =
    localStorage.getItem(LocalStorageKey.DefaultAuthScreen) === "login" ? "login" : "create-account"

  const ProtectedComponent: Component = () => (
    <Show when={data.user} fallback={<Navigate href={`/${defaultAuthScreen}`} />}>
      <Show when={renderProps.component} fallback={renderProps.element}>
        <Dynamic component={renderProps.component} />
      </Show>
    </Show>
  )

  return <Route {...routeProps} component={ProtectedComponent} />
}
