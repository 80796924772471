import { FirebaseOptions, initializeApp } from "firebase/app"
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check"
import { getAuth } from "firebase/auth"
import {
  Timestamp,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from "firebase/firestore"
import { getPerformance } from "firebase/performance"

const config: FirebaseOptions = {
  projectId: import.meta.env.APP_FIREBASE_PROJECT_ID,
  appId: import.meta.env.APP_FIREBASE_APP_ID,
  authDomain: import.meta.env.APP_FIREBASE_AUTH_DOMAIN,
  apiKey: import.meta.env.APP_FIREBASE_API_KEY,
  storageBucket: import.meta.env.APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: import.meta.env.APP_COOL,
}

export const app = initializeApp(config)

export const perf = getPerformance(app)

if (import.meta.env.DEV && !window.location.hostname.includes("jotik.app"))
  // @ts-expect-error todo extent Window
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(import.meta.env.APP_FIREBASE_RECAPTCHA_KEY),
  isTokenAutoRefreshEnabled: true,
})

export const auth = getAuth(app)

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
})

export function initializeFirebase() {
  if (app) console.log(`Firebase ${app.options.projectId} initialized`)
}

export function timestampFrom(date: Date | null | undefined | any): Timestamp | null | undefined {
  return date instanceof Date ? Timestamp.fromDate(date) : date
}

export function dateFrom(timestamp: Timestamp | null | undefined): Date | null {
  return timestamp?.toDate() ?? null
}
