import { StoreSetter } from "solid-js/store"
import { AppData, SetAppData } from "./types"

export function setupFilters(data: AppData, setData: SetAppData) {
  function search(term: StoreSetter<string, ["searchTerm", "filters"]>) {
    setData("filters", "searchTerm", term)
  }

  function filter<K extends "tasksOnly" | "archiveOnly">(
    key: K,
    value: StoreSetter<boolean, [K, "filters"]>,
  ) {
    setData("filters", key, value)
  }

  return { search, filter }
}
