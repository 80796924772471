import { getLocalData } from "../../utils/LocalDataStorage"
import { LocalStorageKey } from "../types"
import { MigrateNote } from "./types"

// {"notes":[{"id":"fc0cf3ea-a79c-4d2e-a1f2-12de29d4f61f","points":[{"text":"#Migrate test 2","completed":false},{"text":"done!","completed":false}],"tags":["migrate"],"author":"","createdAt":"2022-12-01T10:06:32.154Z","allCompletedAt":null}]}

type LegacyLocalNote = {
  id: string
  points: {
    text: string
    completed: boolean
  }[]
  tags: string[]
  author: string
  createdAt: Date | null
  allCompletedAt: Date | null
}
type LegacyLocalNotesData = { notes: LegacyLocalNote[] }

export async function localStorageNotesMigration(
  notesPromise: Promise<MigrateNote[]>,
  userId: string,
): Promise<MigrateNote[]> {
  const localNotes = getLocalData<LegacyLocalNotesData>(LocalStorageKey.LegacyNotes)
  const hasMigratedLocalNotes = localStorage.getItem(LocalStorageKey.NotesMigration) === "true"
  localStorage.setItem(LocalStorageKey.NotesMigration, "true")

  if (hasMigratedLocalNotes || !localNotes?.notes?.length) return notesPromise

  try {
    const notes = await notesPromise
    const now = new Date()

    const newNotes = localNotes.notes.map<MigrateNote>((note) => ({
      points: note.points,
      lexicalContent: "",
      plainContent: "",
      tags: note.tags,
      owner: userId,
      editors: [userId],
      createdAt: note.createdAt ?? now,
      updatedAt: note.createdAt ?? now,
      allCompletedAt: note.allCompletedAt ?? null,
      archivedAt: null,
      serverSyncedAt: null,
      dirty: true,
    }))

    return [...notes, ...newNotes]
  } catch (error) {
    console.error("Migration of localStorage notes failed", error)
    localStorage.setItem(LocalStorageKey.NotesMigration, "false")
    return notesPromise
  }
}

export function hasPendingLocalNotesMigration() {
  const hasLocalNotes = localStorage.getItem(LocalStorageKey.LegacyNotes) !== null
  const hasNotMigrated = localStorage.getItem(LocalStorageKey.NotesMigration) !== "true"

  return hasLocalNotes && hasNotMigrated
}
