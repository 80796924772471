import { Component, createSignal } from "solid-js"
import { useAppData } from "../../data/AppData"
import { RichTextConfig } from "../rich-text/RichText"
import { Scrollable } from "../Scrollable"
import styles from "./NoteEditor.module.scss"
import { NoteEditorTextInput } from "./NoteEditorTextInput"
import { NoteEditorToolbar } from "./NoteEditorToolbar"

export const NoteEditor: Component = () => {
  const [data] = useAppData()

  const [tagSearch, setTagSearch] = createSignal<string | null>(null)
  const [isEmpty, setIsEmpty] = createSignal(true)

  return (
    <div class={styles.editor}>
      <RichTextConfig namespace="NoteEditor" editorState={data.draftNote.lexicalContent} editable>
        <Scrollable class={styles.textBox} scrollbar>
          <NoteEditorTextInput
            setTagSearch={(tag) => setTagSearch(tag)}
            setIsEmpty={(empty) => setIsEmpty(empty)}
          />
        </Scrollable>
        <NoteEditorToolbar tagSearch={tagSearch()} saveDisabled={isEmpty()} />
      </RichTextConfig>
    </div>
  )
}
