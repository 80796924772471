import { useNavigate } from "@solidjs/router"
import {
  BiRegularFilter,
  BiRegularSearch,
  BiRegularUndo,
  BiRegularUserCircle,
  BiRegularX,
} from "solid-icons/bi"
import { Show, createEffect, createSignal } from "solid-js"
import { Transition } from "solid-transition-group"
import { useAppData } from "../../data/AppData"
import { Analytics } from "../../utils/Analytics"
import { createShortcut } from "../../utils/KeyboardShortcuts"
import { es } from "../../utils/style"
import { getCurrentWord } from "../../utils/text"
import { HorizontalSelector } from "../HorizontalSelector"
import { TagSelector } from "../TagSelector"
import { Header, HeaderButton, HeaderRow } from "./Header"
import headerStyles from "./Header.module.scss"
import styles from "./NotesHeader.module.scss"

type InputEvent = globalThis.InputEvent & {
  currentTarget: HTMLInputElement
  target: Element
}

export const NotesHeader = () => {
  let inputRef: HTMLInputElement

  const [data, { search }] = useAppData()
  const [isSearchOpen, setIsSearchOpen] = createSignal(false)
  const [isFiltersOpen, setIsFiltersOpen] = createSignal(false)
  const [tagSearch, setTagSearch] = createSignal("")

  createShortcut("ctrl+f, command+f", () => inputRef.focus())

  const onSearchInput = (event: InputEvent) => {
    const text = event.currentTarget.value
    const caretIndex = event.currentTarget.selectionStart ?? 0
    search(text)

    const { currentWord } = getCurrentWord(text, caretIndex)
    setTagSearch((currentWord[0] === "#" ? currentWord.slice(1) : currentWord).toLowerCase())
  }

  const completeTag = (tag: string) => {
    const caretIndex = inputRef?.selectionStart ?? 0
    const term = data.filters.searchTerm
    const { startIndex, currentWord } = getCurrentWord(term, caretIndex)
    const updatedSearch = `${term.slice(0, startIndex)}#${tag} ${term
      .slice(startIndex + currentWord.length)
      .trimStart()}`

    search(updatedSearch)
    setTagSearch("")
    Analytics.event(Analytics.Event.searchTagAutocompleted)
  }

  createEffect(function emitSearchAnalyticsEvent(hadFocus = false) {
    if (!hadFocus && isSearchOpen()) Analytics.event(Analytics.Event.searchStarted)
    return isSearchOpen()
  })

  const toggleSearch = (value?: boolean) => {
    setIsSearchOpen((prev) => value ?? !prev)
    setIsFiltersOpen(false)
  }

  const toggleFilters = (value?: boolean) => {
    setIsFiltersOpen((prev) => value ?? !prev)
    setIsSearchOpen(false)
  }

  const clear = () => {
    search("")
    setTagSearch("")
  }

  return (
    <Header hasRows>
      <HeaderRow>
        <section />

        <section class={styles.searchSection}>
          <label class={styles.search}>
            <div class={es(styles.icon, styles.searchIcon)}>
              <BiRegularSearch />
            </div>

            <input
              ref={(el) => (inputRef = el)}
              type="search"
              placeholder="Search"
              value={data.filters.searchTerm}
              onInput={onSearchInput}
              onFocus={() => toggleSearch(true)}
              onBlur={() => toggleSearch(false)}
            />

            <Show when={data.filters.searchTerm.length > 0}>
              <button
                title="Clear search"
                class={es(styles.icon, styles.clearSearch)}
                onClick={clear}
              >
                <BiRegularX />
              </button>
            </Show>
          </label>
        </section>

        <section>
          <FilterButton isOpen={isFiltersOpen()} onClick={() => toggleFilters()} />
          <UndoButton />
          <UserButton />
        </section>
      </HeaderRow>

      <Transition name="open-fade">
        <Show when={isSearchOpen()}>
          <TagSelector
            class={styles.selector}
            tagSearch={tagSearch()}
            onTagSelect={completeTag}
            showAllWhenEmpty
          />
        </Show>
      </Transition>
      <Transition name="open-fade">
        <Show when={isFiltersOpen()}>
          <FilterSelector />
        </Show>
      </Transition>
    </Header>
  )
}

const UserButton = () => {
  const navigate = useNavigate()

  return (
    <HeaderButton
      onClick={() => navigate("/preferences")}
      title="Preferences"
      icon={BiRegularUserCircle}
    />
  )
}

const FilterButton = (props: { isOpen: boolean; onClick: () => void }) => {
  const [data] = useAppData()

  const isFiltering = () => data.filters.archiveOnly || data.filters.tasksOnly

  return (
    <HeaderButton
      onClick={props.onClick}
      title="Filter notes"
      icon={BiRegularFilter}
      classList={{ [headerStyles.highlight]: isFiltering() }}
    />
  )
}

const UndoButton = () => {
  const [data, { undoAction }] = useAppData()
  const [highlight, setHighlight] = createSignal(false)
  const hasUndoableActions = () => data.recentActions.length > 0

  createEffect<number>(function highlightFor3Secs(numActions = 0) {
    if (data.recentActions.length > numActions) {
      setHighlight(true)
      setTimeout(() => setHighlight(false), 3000)
    }
    return data.recentActions.length
  })

  return (
    <Show when={hasUndoableActions()}>
      <HeaderButton
        classList={{ [headerStyles.highlight]: highlight() }}
        onClick={() => undoAction()}
        title="Undo"
        icon={BiRegularUndo}
      />
    </Show>
  )
}

const FilterSelector = () => {
  const [data, { filter }] = useAppData()

  let selectorRef!: HTMLDivElement

  const filters = () => [
    { title: "Tasks", value: "tasksOnly", isSelected: data.filters.tasksOnly },
    { title: "Archive", value: "archiveOnly", isSelected: data.filters.archiveOnly },
  ]

  const onSelect = (key: string) => {
    filter(key as "tasksOnly" | "archiveOnly", (prev) => !prev)
  }

  return (
    <HorizontalSelector
      ref={selectorRef}
      options={filters()}
      onSelect={onSelect}
      class={styles.selector}
    />
  )
}
