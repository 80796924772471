type CurrentWord = { currentWord: string; startIndex: number }

export const getCurrentWord = (text: string, caretIndex: number): CurrentWord => {
  const startIndex = getCurrentWordStartIndex(text, caretIndex)

  const currentWord = text.slice(startIndex).split(/\s|$/, 1)[0] ?? ""
  return { startIndex, currentWord }
}

function getCurrentWordStartIndex(text: string, caretIndex: number): number {
  for (let i = caretIndex - 1; i >= 0; i--) if (/\s/.test(text[i])) return i + 1
  return 0
}
