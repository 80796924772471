import { useNavigate } from "@solidjs/router"
import { IconTypes } from "solid-icons"
import { BiRegularLeftArrowAlt } from "solid-icons/bi"
import { children, Component, JSX, splitProps } from "solid-js"
import { Dynamic } from "solid-js/web"
import { es } from "../../utils/style"
import styles from "./Header.module.scss"

type DivProps = JSX.HTMLAttributes<HTMLDivElement>

type HeaderProps = DivProps & {
  noShadow?: boolean
  hasRows?: boolean
}

export const Header: Component<HeaderProps> = (props) => {
  const [headerProps, divProps] = splitProps(props, ["noShadow"])

  return (
    <div
      class={styles.header}
      classList={{
        [styles.shadow]: !headerProps.noShadow,
        [styles.row]: !props.hasRows,
      }}
      {...divProps}
    >
      {props.children}
    </div>
  )
}

export const HeaderRow: Component<DivProps> = (props) => (
  <div {...props} class={es(styles.row, props.class)} />
)

type HeaderButtonProps = {
  title: string
  icon?: IconTypes
  onClick: () => void
} & Pick<
  JSX.ButtonHTMLAttributes<HTMLButtonElement>,
  "children" | "disabled" | "onClick" | "title" | "style" | "class" | "classList"
>

export const HeaderButton: Component<HeaderButtonProps> = (_props) => {
  const [props, otherProps] = splitProps(_props, ["icon", "class", "classList", "children"])

  const resolvedChildren = children(() => props.children)
  const hasIconOnly = () => resolvedChildren.toArray().length === 0 && props.icon !== undefined

  return (
    <button
      {...otherProps}
      class={es(styles.button, props.class)}
      classList={{ ...props.classList, [styles.iconOnly]: hasIconOnly() }}
    >
      <Dynamic component={props.icon} />
      {resolvedChildren()}
    </button>
  )
}

export const BackButton = () => {
  const navigate = useNavigate()

  return <HeaderButton onClick={() => navigate(-1)} title="Go back" icon={BiRegularLeftArrowAlt} />
}
