import { useAppData } from "../data/AppData"
import { HorizontalSelector, HorizontalSelectorOption } from "./HorizontalSelector"

type TagSelectorProps = {
  tagSearch: string
  onTagSelect: (tag: string) => void
  class?: string
  includeSearchAsTag?: boolean
  showAllWhenEmpty?: boolean
}

export const TagSelector = (props: TagSelectorProps) => {
  const [data] = useAppData()

  const options = (): HorizontalSelectorOption[] => {
    const tagSearch = props.tagSearch
    if (tagSearch === null) return []

    let fTags = [...data.tags.byCumulativeUsage.map(({ tag }) => tag)]
    if (tagSearch !== "") fTags = fTags.filter((tag) => tag.startsWith(tagSearch)).slice(0, 20)

    if (props.includeSearchAsTag && tagSearch.length > 0 && !fTags.includes(tagSearch))
      fTags.push(tagSearch)

    if (props.showAllWhenEmpty && fTags.length === 0)
      fTags = [...data.tags.byCumulativeUsage.map(({ tag }) => tag)]

    return fTags.map((tag) => ({ title: `#${tag}`, value: tag }))
  }

  return (
    <HorizontalSelector
      options={options()}
      class={props.class}
      onSelect={props.onTagSelect}
      placeholder={props.tagSearch}
    />
  )
}

export const NoTag = (props: { children: string }) => (
  <span>
    No <strong>#{props.children.trim()}</strong> tag exists
  </span>
)
