enum Event {
  noteStarted = "Note Started",
  noteSaved = "Note Saved",
  noteEditingOpened = "Note Editing Opened",
  noteDeleted = "Note Deleted",
  notePointChecked = "Note Point Checked",
  notePointDeleted = "Note Point Deleted",
  noteFromPaste = "Note from Paste",
  noteTagStarted = "Note Tag Started",
  noteTagAutocompleted = "Note Tag Autocompleted",
  noteLinkAdded = "Note Link Added",
  noteLinkRemoved = "Note Link Removed",
  noteImageAdded = "Note Image Added",
  noteImageRemoved = "Note Image Removed",
  noteFileAdded = "Note File Added",
  noteFileRemoved = "Note File Removed",
  noteReminderAdded = "Note Reminder Added",
  noteReminderRemoved = "Note Reminder Removed",
  noteDictationAdded = "Note Dictation Added",
  noteDictationRemoved = "Note Dictation Removed",
  undo = "Undo",
  feedScrolled = "Feed Scrolled",
  searchStarted = "Search Started",
  searchReady = "Search Ready",
  searchTagStarted = "Search Tag Started",
  searchTagAutocompleted = "Search Tag Autocompleted",
  linkOpened = "Link Opened",
  appLaunched = "App Launched",
  appInstalled = "App Installed",
  userSignedUp = "User Signed Up",
  userSignedIn = "User Signed In",
  userSignedOut = "User Signed Out",
  userConvertedToKnownAccount = "User Converted to Known Account",
}

export default Event
