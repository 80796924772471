import { onLoad } from "@sentry/browser"
import { $getRoot, EditorState } from "lexical"
import { useLexicalComposerContext } from "lexical-solid/LexicalComposerContext"
import { Component, ErrorBoundary, Show, createEffect, createSignal, on } from "solid-js"
import { useAppData } from "../../data/AppData"
import { Note } from "../../data/types"
import { Analytics } from "../../utils/Analytics"
import { formatDate } from "../../utils/DateFormat"
import { es } from "../../utils/style"
import { RichText, RichTextConfig } from "../rich-text/RichText"
import styles from "./Note.module.scss"
import { NoteContextMenu } from "./NoteContextMenu"

type NoteCardProps = { note: Note }

export const NoteCard: Component<NoteCardProps> = (props) => {
  const [data] = useAppData()

  const [isInfoVisible, setIsInfoVisible] = createSignal(false)

  createEffect(function infoVisibleFor3Secs(prevTimeoutId?: NodeJS.Timeout) {
    clearTimeout(prevTimeoutId)
    if (isInfoVisible()) return setTimeout(() => setIsInfoVisible(false), 3000)
  })

  const isBeingEdited = () => props.note.id === data.draftNote.id

  return (
    <RichTextConfig namespace="Note" editorState={props.note.lexicalContent}>
      <ErrorBoundary fallback={(error) => <NoteErrorCard note={props.note} error={error} />}>
        <div
          class={styles.note}
          classList={{
            [styles.drafting]: isBeingEdited(),
            [styles.allCompeted]: !!props.note.allCompletedAt,
          }}
          onClick={() => setIsInfoVisible((prev) => !prev)}
        >
          <div class={styles.info} classList={{ [styles.infoVisible]: isInfoVisible() }}>
            <div>{formatDate(props.note.createdAt)}</div>
            <NoteContextMenu note={props.note} />
          </div>
          <div class={styles.content}>
            <NoteContent note={props.note} />
          </div>
        </div>
      </ErrorBoundary>
    </RichTextConfig>
  )
}

const NoteContent: Component<NoteCardProps> = (props) => {
  const [, { saveNote, search }] = useAppData()
  const [editor] = useLexicalComposerContext()

  const onChange = (editorState: EditorState) => {
    const lexicalContent = JSON.stringify(editorState)
    if (lexicalContent !== props.note.lexicalContent) {
      editorState.read(() => {
        const rootNode = $getRoot()
        const plainContent = rootNode.getTextContent()
        saveNote({ id: props.note.id, lexicalContent, plainContent })
      })
    }
  }

  createEffect(
    on(
      () => props.note.lexicalContent,
      function syncNoteUpdatesToEditor(lexicalContent) {
        const editorLexicalContent = JSON.stringify(editor.getEditorState())

        if (lexicalContent && lexicalContent !== editorLexicalContent) {
          editor.update(() => {
            const state = editor.parseEditorState(lexicalContent)
            editor.setEditorState(state)
          })
        }
      },
      { defer: true },
    ),
  )

  return <RichText onChange={onChange} onTagClick={search} />
}

type NoteErrorCardProps = { error: any; note: Note }
const NoteErrorCard: Component<NoteErrorCardProps> = (props) => {
  onLoad(() => {
    console.warn(`Error in note ${props.note.id}.`)
    Analytics.error(props.error)
  })

  return (
    <Show when={import.meta.env.DEV}>
      <div class={es(styles.note, styles.noteError)}>
        ⚠️ {String(props.error)} in note {props.note.id}.
      </div>
    </Show>
  )
}
