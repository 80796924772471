import { Component, JSX, splitProps } from "solid-js"
import { es } from "../utils/style"
import styles from "./Scrollable.module.scss"

type Props = JSX.HTMLAttributes<HTMLDivElement> & {
  horizontal?: boolean
  scrollbar?: boolean
}

export const Scrollable: Component<Props> = (allProps) => {
  const [props, divProps] = splitProps(allProps, ["horizontal", "scrollbar"])

  return (
    <div
      {...divProps}
      class={es(styles.scrollable, divProps.class)}
      classList={{
        [styles.horizontalScroll]: props.horizontal,
        [styles.hideScrollbar]: !props.scrollbar,
      }}
    />
  )
}
