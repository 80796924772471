import { For } from "solid-js"
import { Note } from "../../data/types"
import { Scrollable } from "../Scrollable"
import { NoteCard } from "../note/Note"
import styles from "./NotesFeed.module.scss"

type Props = {
  notes: Note[]
}

export const NotesFeed = (props: Props) => {
  return (
    <Scrollable class={styles.notesFeed}>
      <For each={props.notes}>{(note) => <NoteCard note={note} />}</For>
    </Scrollable>
  )
}
