import { $getRoot, createEditor } from "lexical"
import { IconTypes } from "solid-icons"
import {
  BiRegularArchive,
  BiRegularArchiveOut,
  BiRegularDotsHorizontalRounded,
  BiRegularEdit,
  BiRegularTrash,
} from "solid-icons/bi"
import { Show, createSignal } from "solid-js"
import { Dynamic, Portal } from "solid-js/web"
import { useAppData } from "../../data/AppData"
import { ActionType, Note } from "../../data/types"
import { NODES } from "../rich-text/RichText"
import styles from "./NoteContextMenu.module.scss"

type NoteContextMenuProps = {
  note: Note
}

export const NoteContextMenu = (props: NoteContextMenuProps) => {
  const [isOpen, setIsOpen] = createSignal(false)
  const [
    data,
    {
      deleteNote,
      setDraftNote,
      completeAllNoteCheckboxes,
      incompleteAllNoteCheckboxes,
      addAction,
      archiveNote,
    },
  ] = useAppData()

  const editItWithAlert = () => {
    navigator.vibrate?.(10)

    if (data.draftNote.lexicalContent !== undefined) {
      createEditor({ nodes: NODES })
        .parseEditorState(data.draftNote.lexicalContent)
        .read(() => {
          const rootNode = $getRoot()
          const text = rootNode.getTextContent()
          console.log("text", text.length)
          if (
            text.length === 0 ||
            confirm(`You’re already editing another note. Do you want to discard it?`)
          )
            setDraftNote(props.note)
        })
    } else {
      setDraftNote(props.note)
    }
  }

  const markAllPointsComplete = () => {
    navigator.vibrate?.(10)
    completeAllNoteCheckboxes(props.note)
  }

  const markAllPointsIncomplete = () => {
    navigator.vibrate?.(10)
    incompleteAllNoteCheckboxes(props.note)
  }

  const archiveIt = () => {
    navigator.vibrate?.(10)
    archiveNote(props.note, true)
  }

  const unarchiveIt = () => {
    navigator.vibrate?.(10)
    archiveNote(props.note, false)
  }

  const deleteIt = () => {
    navigator.vibrate?.(50)
    addAction(ActionType.noteDeletion, { note: props.note })
    deleteNote(props.note)
  }

  return (
    <>
      <div
        title="Open note menu"
        class={styles.contextMenuButton}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <BiRegularDotsHorizontalRounded />
      </div>

      <Show when={isOpen()}>
        <Portal>
          <div class={styles.contextMenuModalBackdrop} onClick={() => setIsOpen(false)}>
            <div class={styles.contextMenuModal}>
              <ContextMenuItem icon={BiRegularEdit} onClick={editItWithAlert}>
                Edit
              </ContextMenuItem>
              {/* <ContextMenuItem icon={BiRegularCheckSquare} onClick={markAllPointsComplete}>
                Mark all complete
              </ContextMenuItem>
              <ContextMenuItem icon={BiRegularSquare} onClick={markAllPointsIncomplete}>
                Mark all incomplete
              </ContextMenuItem> */}
              <Show
                when={!props.note.archivedAt}
                fallback={
                  <ContextMenuItem icon={BiRegularArchiveOut} onClick={unarchiveIt}>
                    Unarchive
                  </ContextMenuItem>
                }
              >
                <ContextMenuItem icon={BiRegularArchive} onClick={archiveIt}>
                  Archive
                </ContextMenuItem>
              </Show>
              <hr />
              <ContextMenuItem icon={BiRegularTrash} onClick={deleteIt}>
                Delete
              </ContextMenuItem>
            </div>
          </div>
        </Portal>
      </Show>
    </>
  )
}

type ContextMenuItemProps = {
  icon: IconTypes
  onClick: () => void
  children: string
}
const ContextMenuItem = (props: ContextMenuItemProps) => (
  <div class={styles.contextMenuItem} onClick={() => props.onClick()}>
    <div>
      <Dynamic component={props.icon} />
    </div>
    <div>{props.children}</div>
  </div>
)
