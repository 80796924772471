import { BackButton, Header } from "../header/Header"
import styles from "./Policy.module.scss"
const PRIVACY_POLICY_PATH = "/privacyPolicy.html"

export const PrivacyPolicyScreen = () => {
  return (
    <div class={styles.policyPage}>
      <Header>
        <section>
          <BackButton />
        </section>
      </Header>

      <iframe src={PRIVACY_POLICY_PATH} />
    </div>
  )
}
