import type { Transformer } from "@lexical/markdown"
import { registerMarkdownShortcuts, TRANSFORMERS } from "@lexical/markdown"
import { useLexicalComposerContext } from "lexical-solid/LexicalComposerContext"
import { createEffect, mergeProps, onCleanup } from "solid-js"

export function LexicalMarkdownShortcutPlugin(
  props: Readonly<{ transformers?: Transformer[] }>,
): null {
  const mergedProps = mergeProps({ transformers: TRANSFORMERS }, props)
  const [editor] = useLexicalComposerContext()

  createEffect(() => {
    onCleanup(registerMarkdownShortcuts(editor, mergedProps.transformers))
  })

  return null
}
