import * as Amplitude from "@amplitude/analytics-browser"
import * as Sentry from "@sentry/browser"
import { trace } from "firebase/performance"
import { version as appVersion } from "../../package.json"
import { LocalStorageKey, User } from "../data/types"
import { perf } from "../firebase"
import Event from "./AnalyticsEvents"
import { getLocalData } from "./LocalDataStorage"

const LOCAL_ANALYTICS_ENABLED = false
const ANALYTICS_ENABLED = import.meta.env.PROD || LOCAL_ANALYTICS_ENABLED

/** Initialize Sentry and Amplitude */
async function initialize() {
  initializeSentry()
  initializeAmplitude()
  console.log(`Analytics initialized: ${import.meta.env.MODE} ${appVersion}`)
}

function initializeSentry() {
  Sentry.init({
    dsn: import.meta.env.APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: import.meta.env.MODE,
    release: `jotik-app@${appVersion}`,
    enabled: ANALYTICS_ENABLED,
  })
}

function initializeAmplitude() {
  const user = getLocalData<User>(LocalStorageKey.User)
  const API_KEY = import.meta.env.APP_AMPLITUDE_API_KEY

  Amplitude.setOptOut(!ANALYTICS_ENABLED)
  Amplitude.init(API_KEY, user?.id, { appVersion, defaultTracking: true })
}

/** Identify the user in Amplitude and Sentry */
function setUserId(userId: string) {
  Amplitude.setUserId(userId)
  Sentry.setUser({ id: userId })
  console.log("Analytics set user ID:", userId)
}

/** Unset the user identified in Amplitude and Sentry. */
function unsetUser(): void {
  Amplitude.reset()
  Sentry.setUser(null)
  console.log("Analytics unset user")
}

export type Properties = Record<string, string | number | string[] | number[] | boolean>

/** Track an event in Amplitude */
function event(event: Event, properties?: Properties) {
  Amplitude.track(event, properties)
  console.log("Analytics event:", event, properties ?? "")
}

/** Track an error in Sentry */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function error(error: unknown) {
  Sentry.captureException(error)
  console.log("Analytics error:", error)
}

/** Log a message in Sentry */
function log(message: string, severity?: Sentry.SeverityLevel) {
  Sentry.captureMessage(message, severity)
  console.log("Analytics log:", message)
}

function perfTimer(label: string): () => void {
  const tracer = trace(perf, label)
  tracer.start()

  let running = true
  return () => {
    if (running) tracer.stop()
    running = false
  }
}

export const Analytics = {
  initialize,
  setUserId,
  unsetUser,
  event,
  error,
  log,
  perfTimer,
  Event: Event,
}
