import { A } from "@solidjs/router"
import { Auth } from "../../utils/Auth"

export const ProfileScreen = () => (
  <>
    <h1>Profile</h1>
    <A href="/">Home</A>
    <br />
    <A href="/privacy-policy">Privacy Policy</A>
    <br />
    <A href="/terms-and-conditions">Terms and Conditions</A>
    <br />
    <button onClick={() => Auth.signOut()}>Logout</button>
  </>
)
