import { $getSelection, EditorState } from "lexical"
import { Component, createEffect } from "solid-js"
import { useAppData } from "../../data/AppData"

import { $isHashtagNode } from "@lexical/hashtag"
import { $getRoot } from "lexical"
import { useLexicalComposerContext } from "lexical-solid/LexicalComposerContext"
import { debounce } from "../../utils/debounce"
import { RichText } from "../rich-text/RichText"

type NoteEditorTextInputProps = {
  setTagSearch: (tag: string | null) => void
  setIsEmpty: (isEmpty: boolean) => void
  class?: string
}

export const NoteEditorTextInput: Component<NoteEditorTextInputProps> = (props) => {
  const [data, { updateDraftContent }] = useAppData()
  const [editor] = useLexicalComposerContext()

  createEffect(function syncDraftUpdatesToEditor(prevId: string | undefined) {
    const lexicalContent = data.draftNote.lexicalContent
    if (data.draftNote.id !== prevId && lexicalContent) {
      editor.update(() => {
        const state = editor.parseEditorState(lexicalContent)
        editor.setEditorState(state)
      })
    }

    return data.draftNote.id
  })

  const updateDraft = debounce(updateDraftContent, 500)

  const onChange = (editorState: EditorState) => {
    editorState.read(() => {
      const rootNode = $getRoot()
      const text = rootNode.getTextContent()
      props.setIsEmpty(text.length === 0)

      const currentNode = $getSelection()?.getNodes()[0]

      const hashtag = $isHashtagNode(currentNode)
        ? currentNode.getTextContent().slice(1).toLowerCase()
        : null
      props.setTagSearch(hashtag)

      const lexicalContent = JSON.stringify(editorState)
      if (data.draftNote.lexicalContent !== lexicalContent) updateDraft(lexicalContent)
    })
  }

  return <RichText placeholder="Jot down a thought" onChange={onChange} class={props.class} />
}
