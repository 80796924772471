import { createSignal, onCleanup, onMount, ParentComponent } from "solid-js"
import styles from "./AppContainer.module.scss"

/**
 * Mostly a workaround for browsers that don’t yet respect the `interactive-widget=resizes-content`
 * property of the viewport `<meta>` tag.
 *
 * We need the layout viewport to resize when the keyboard widget appears so that the editor
 * controls and search bar both stay visible. This is not the default on any browser.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag#the_effect_of_interactive_ui_widgets
 */
export const AppContainer: ParentComponent = (props) => {
  const [height, setHeight] = createSignal<number>(window.visualViewport!.height)

  onMount(() => {
    const getAndUpdateHeight = () => {
      setHeight(window.visualViewport!.height)
    }
    window.visualViewport!.addEventListener("resize", getAndUpdateHeight)
    onCleanup(() => window.visualViewport!.removeEventListener("resize", getAndUpdateHeight))
  })

  return (
    <div class={styles.appContainer} style={{ height: height() + "px" }}>
      {props.children}
    </div>
  )
}
